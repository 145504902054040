import React from "react";
import Product from "../../components/admin/product/Product.component";

const Menu = () => {
  return (
    <div>
      <Product />
    </div>
  );
};

export default Menu;
