import React from "react";

import Slider from "../../components/slider/Slider.component";

const Home = () => {
  return (
    <div>
      <Slider />
    </div>
  );
};

export default Home;
